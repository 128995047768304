import { faBinoculars } from "@fortawesome/free-solid-svg-icons/faBinoculars";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import GenericModal from "components/Common/GenericModal";
import ListCarousel from "components/Common/Lists/ListCarousel";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
    width: "100%",
    padding: "0 1.25rem .5rem",
    margin: " 2rem auto 0",

    [ScreenSizes.mdAndAbove]: {
      maxWidth: "33rem",
      margin: "0 auto",
    },
  },
  description: {
    ...gStyles.fontRegular,
    color: colours.black,
    fontSize: "14px",
    lineHeight: "28px",
    padding: "1.2rem 0 2rem",
    textAlign: "center",

    [ScreenSizes.mdAndAbove]: {
      textAlign: "left",
      fontSize: "13px",
      lineHeight: "22px",
    },
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "100%",
  },
  action: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: colours.oldSecondary,
    marginBottom: "3rem",

    [ScreenSizes.mdAndAbove]: {
      marginBottom: 0,
    },
  },
  centralAction: {
    [ScreenSizes.mdAndAbove]: {
      padding: "0 .3rem",
    },
  },
  leftAction: {
    [ScreenSizes.mdAndAbove]: {
      paddingRight: ".3rem",
    },
  },
  rightAction: {
    [ScreenSizes.mdAndAbove]: {
      paddingLeft: ".3rem",
    },
  },
  actionIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "7.4rem",
    minWidth: "7.4rem",
    height: "7.4rem",
    borderRadius: "50%",
    backgroundColor: "#f2f2f2",
    fontSize: "3rem",

    [ScreenSizes.mdAndAbove]: {
      width: "4rem",
      minWidth: "4rem",
      height: "4rem",
      fontSize: "2.2rem",
    },
  },
  actionTitle: {
    ...gStyles.fontSemiBold,
    margin: ".8rem 0 .6rem",
    fontSize: "1rem",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "19px",
    },
  },
  actionDescription: {
    ...gStyles.fontMedium,
    fontSize: "15px",
    lineHeight: "22px",
    color: colours.midGrey,
    marginBottom: "1rem",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
};

const modalStyles = {
  genericModal: {
    padding: 0,
  },
  closeButton: {
    fontSize: "1.2rem",
  },
};

const MOBILE_SLIDE_PROPS = {
  slideInFrom: "bottom",
  fullWidth: true,
  fullHeight: true,
  showOverlay: true,
  styles: {
    childrenInner: {
      height: "calc(100% - 2rem)",
      top: "2rem",
      borderRadius: 0,
    },
  },
};

const ACTIONS = [
  {
    className: "leftAction",
    icon: faPlus,
    title: "Create",
    description:
      "Create custom podcast lists and a profile featuring your favorite podcasts.",
  },
  {
    className: "centralAction",
    icon: faBinoculars,
    title: "Discover",
    description:
      "Discover great podcasts by following your friends and podcast Creators (coming soon).",
  },
  {
    className: "rightAction",
    icon: faPencilAlt,
    title: "Contribute",
    description:
      "Help the community find new podcasts by rating and reviewing podcasts.",
  },
];

const Top8EditorInfoModal = (props) => {
  const { onClose } = props;
  const { styles } = useStyles(baseStyles, props);

  const { isWindowSizeOrLess } = useWindowSize();

  const renderAction = useCallback(
    (action, index) => (
      <div key={index} className={css(styles.action, styles[action.className])}>
        <div className={css(styles.actionIcon)}>
          <FontAwesomeIcon icon={action.icon} />
        </div>
        <div className={css(styles.actionTitle)}>{action.title}</div>
        <div className={css(styles.actionDescription)}>
          {action.description}
        </div>
      </div>
    ),
    [styles]
  );

  const renderContent = () => (
    <div className={css(styles.content)}>
      <div className={css(styles.description)}>
        Podchaser is a social podcast discovery platform built for your unique
        podcast personality.
      </div>
      <div className={css(styles.actionsContainer)}>
        <ListCarousel
          items={ACTIONS}
          renderItem={renderAction}
          carouselAtSize="small"
          minCarouselItemPercentage={60}
          showControls
        />
      </div>
    </div>
  );

  return (
    <GenericModal
      renderContent={renderContent}
      title="What is Podchaser?"
      onClose={onClose}
      styles={modalStyles}
      slideProps={isWindowSizeOrLess("small") && MOBILE_SLIDE_PROPS}
      smallTitle
    />
  );
};

Top8EditorInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default memo(Top8EditorInfoModal);
