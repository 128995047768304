import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Top8Podcasts = Loadable(() => import("./Top8Podcasts"));

function Top8PodcastsAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const Top8PodcastsContainer = (props) => {
  return <Top8Podcasts {...props} fallback={<Top8PodcastsAsyncLoading />} />;
};

export default Top8PodcastsContainer;
