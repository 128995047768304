import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons/faShareSquare";
import { faSmile } from "@fortawesome/free-solid-svg-icons/faSmile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { fromJS, List } from "immutable";
import range from "lodash/range";
import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import {
  memo,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactResizeDetector from "react-resize-detector";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { updateProfilePromise } from "routines/profile";

import StandardButton from "components/Buttons/StandardButton";
import LoadingOverlay from "components/Common/LoadingOverlay";
import Top8Podcasts from "components/Users/Top8Podcasts/Top8PodcastsAsync";
import UserProfileSharingLinks from "components/Users/UserProfileSharingLinksAsync";
import Top8EditorStructuredData from "pages/UserProfile/Top8Editor/Top8EditorStructuredData";

import PodchaserBrandLogo from "../../../components/Branding/PodchaserBrandLogo";
import Top8EditorInfoModal from "./Top8EditorInfoModal";

import authActions from "actions/auth";
import paginationActions from "actions/pagination";
import * as authStates from "constants/auth-states";
import { SORT_ORDER_RANKING } from "constants/sort";
import loadPodcastsList from "sagas/pagination/lists/loadPodcastsList";
import { selectAuthLoggingIn } from "selectors/auth";
import { selectListIds } from "selectors/pagination";
import { selectSpecificPodcast } from "selectors/podcast";
import cachedImage from "utils/entity/cachedImage";
import getUserUrl from "utils/entity/getUserUrl";
import isEqual from "utils/isEqual";
import { getQueries } from "utils/url";

import useActionCreators from "hooks/useActionCreators";
import { useLocalStorage } from "usehooks-ts";
import useLoggedInUser from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";
import { useTop8Podcasts } from "hooks/useTop8PodcastsForUser";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const overlayStyles = {
  ...gStyles.gradientBackground,
  opacity: "0.95",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const baseStyles = {
  container: {
    position: "relative",
    minWidth: "100%",
    minHeight: "100vh",
  },
  containerTransparentPadding: {
    paddingTop: "6rem",
  },
  defaultBackground: {
    backgroundImage: "url(/images/bg-podcast-grid.png)",
    backgroundPosition: "top",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
  },
  backgroundContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  backgroundImages: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    filter: "blur(3px)",
  },
  backgroundOverlay: {
    ...overlayStyles,
    zIndex: 2,
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    minWidth: "100%",
    minHeight: "100%",
    margin: "4rem 0",
    zIndex: 3,
    color: colours.white,
    textAlign: "center",
  },
  title: {
    ...gStyles.avalonBold,
    margin: "0 1.5rem",
    fontSize: "2rem",
    textTransform: "uppercase",
    textShadow: "0px 8px 6px rgba(0, 0, 0, 0.18)",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "3rem",
    },
  },
  titleWithUser: {
    marginBottom: "2rem",
  },
  description: {
    ...gStyles.fontRegular,
    margin: "2rem",
    fontSize: "1rem",
    lineHeight: "2rem",
    maxWidth: "36rem",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "1.125rem",
    },
  },
  listContainer: {
    maxWidth: "80%",
    width: "100%",
    margin: "0 auto",
    overflow: "hidden",

    [ScreenSizes.lgAndAbove]: {
      maxWidth: "42rem",
      margin: "0 2rem",
    },
  },
  footerButtons: {
    maxWidth: "80%",
    width: "20rem",
    marginBottom: "1rem",
    marginTop: "1rem",

    [ScreenSizes.mdAndAbove]: {
      width: "100%",
      maxWidth: "22rem",
    },
  },
  footerButtonsEditing: {
    maxWidth: "50%",
    [ScreenSizes.mdAndAbove]: {
      maxWidth: "11rem",
    },
  },
  footerButtonsLoggedOut: {
    [ScreenSizes.mdAndAbove]: {
      width: "100%",
      maxWidth: "10rem",
    },
  },
  footerButtonsRow: {
    display: "flex",
    flexDirection: "column",

    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
    },
  },
  footerButtonRowEditing: {
    flexDirection: "column",
    [ScreenSizes.mdAndAbove]: {
      flexDirection: "column",
    },
  },
  footerButton: {
    flex: "1 1 50%",
    width: "50%",
  },
  profileLinkContainer: {
    display: "flex",
    flex: 1,
    marginTop: "1rem",

    [ScreenSizes.mdAndAbove]: {
      marginLeft: "1rem",
      marginTop: 0,
    },
  },
  transparentLink: {
    ...gStyles.fontRegular,
    width: "100%",
    textAlign: "center",
    margin: "1rem 0",
    fontSize: ".813rem",
    color: colours.white,
    cursor: "pointer",
  },
  error: {
    ...gStyles.fontSemiBold,
    width: "100%",
    textAlign: "center",
    margin: "1rem 0",
    fontSize: ".813rem",
    color: colours.negative,
  },
  infoToggle: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    zIndex: 4,
    fontSize: "1.4rem",
    color: colours.white,
    cursor: "pointer",
    display: "flex",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "1.8rem",
      top: "1.7rem",
      right: "2rem",
      display: "flex",
      alignItems: "center",
    },
  },
  infoToggleText: {
    display: "none",
    [ScreenSizes.lgAndAbove]: {
      display: "block",
      fontSize: ".8rem",
      marginRight: ".8em",
    },
    [ScreenSizes.xxlAndAbove]: {
      display: "block",
      fontSize: "1rem",
      marginRight: ".8em",
    },
  },
  podchaserLink: {
    position: "absolute",
    top: ".8rem",
    left: "1rem",
    zIndex: 4,
    fontSize: "1.4rem",
    color: colours.white,
    cursor: "pointer",
    display: "flex",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "1.8rem",
      top: "1.4rem",
      left: "1.4rem",
    },
  },
  podchaserIcon: {
    display: "block",
    width: "1.5rem",
    height: "1.5rem",
  },
  savingContainer: {
    width: "100%",
    height: "40rem",
    backgroundImage: "url(/images/bg-podcast-grid.png)",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  savingOverlay: {
    ...overlayStyles,
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  savingOverlayTitle: {
    ...gStyles.fontSemiBold,
    color: colours.white,
    fontSize: "1.4rem",
    marginTop: "4rem",
  },
  shareButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  shareButton: {
    maxWidth: "12em",

    [ScreenSizes.mdAndAbove]: {
      maxWidth: "11em",
    },
  },
  shareButtonIcon: {
    marginRight: ".5rem",
  },
  loginPrompt: {
    fontSize: ".8em",
    marginBottom: "2rem",
  },
  loginLink: {
    cursor: "pointer",
    color: "white",
    fontWeight: 600,
  },
};

const top8Styles = {
  top8Podcasts: {
    backgroundColor: "transparent",
    borderRadius: 0,
    boxShadow: "none",
    padding: 0,
  },
  titleContainer: {
    display: "none",
  },
};

const top8SquareStyles = {
  gridSquareEmptyItem: {
    backgroundColor: "#fff",
  },
  gridSquareIsBeingDragged: {
    backgroundColor: "#fff",
  },
  gridSquareHasItem: {
    backgroundColor: "transparent",
  },
  gridSquareItemIsOver: {
    backgroundColor: "#fff",
  },
  gridSquareInnerItemIsOver: {
    opacity: 0,
  },

  addItemIcon: {
    fontSize: "3rem",
    opacity: ".4",
    color: colours.black,
  },
  indexBadge: {
    ...gStyles.fontSemiBold,
    fontSize: ".75rem",
    width: "1rem",
    height: "1rem",
  },
};

const loadingStyles = {
  noOverlay: {
    fontSize: "4rem",
    flex: 0,
  },
  icon: {
    maxWidth: "4em",
  },
};

const sharingLinkStyles = {
  button: {
    color: "white",
    padding: "0.5em 1em 0.3rem",
  },
};

const BACKGROUND_PODCASTS_LIST_KEY = "top8landing/backgroundPodcasts";

function Top8Editor(props) {
  const { styles } = useStyles(baseStyles);
  const { transparentPadding } = props;
  const { isWindowSizeOrLess } = useWindowSize();
  const isTinyOrLess = isWindowSizeOrLess("tiny");
  const isSmallOrLess = isWindowSizeOrLess("small");
  const isMediumOrLess = isWindowSizeOrLess("medium");
  const isLargeOrLess = isWindowSizeOrLess("large");

  const history = useHistory();
  const location = useLocation();

  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [returnSaving, setReturnSaving] = useState(false);

  const [editable, setEditable] = useState(false);

  const { updateProfile } = useRoutinePromises({
    updateProfile: updateProfilePromise,
  });
  const user = useLoggedInUser();

  const isFullscreen = location.pathname === "/top8";

  // Forward to /top8 if we get here without a logged in user
  const redirect = isFullscreen && user;

  const loggingIn = useReduxState((state) => selectAuthLoggingIn(state), []);

  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
  });
  const { initList } = useActionCreators({
    initList: paginationActions.initList,
  });

  const [storedSelections, setStoredSelections] = useLocalStorage(
    "podchaser_preregistered_top8",
    null
  );

  useEffect(() => {
    const queries = getQueries(location.search);

    if (queries.top_podcasts && queries.top_podcasts.length > 0 && user) {
      setReturnSaving(true);

      updateProfile({ top_podcasts: queries.top_podcasts })
        .then(() => {
          history.replace(location.pathname);
          setReturnSaving(false);
        })
        .catch(() => {
          setError("Error Saving!");
          setReturnSaving(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only on mount

  useEffect(() => {
    initList({
      key: BACKGROUND_PODCASTS_LIST_KEY,
      sort: SORT_ORDER_RANKING,
      list_type: "podcasts_filtered",
      loadListAction: loadPodcastsList,
      entity_type: "podcast",
      pageSize: 8,
    });
  }, [initList]);

  const backgroundPodcasts = useReduxState(
    (state) =>
      selectListIds(state, BACKGROUND_PODCASTS_LIST_KEY).map((id) =>
        selectSpecificPodcast(state, id)
      ),
    []
  );

  const [selections, setSelections] = useState(List());

  useEffect(() => {
    if (!loggingIn) {
      if (user) {
        setSelections(user.get("top_podcasts"));
        setEditable(false);
      } else if (storedSelections) {
        setSelections(fromJS(storedSelections));
      }
      if (!user) {
        setSelections(fromJS([]));
        setEditable(true);
      }
    }
    // only want to trigger this when loggingIn changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggingIn, user]);

  const selectedTopPodcastEntities = useTop8Podcasts(selections);

  const onChange = useCallback(
    (field, value) => {
      if (selections) {
        setSelections(value);
        setStoredSelections(value.toJS());
      }
    },
    [selections, setStoredSelections]
  );

  const handleResize = useCallback((width, height) => {
    setContainerWidth(width);
    setContainerHeight(height);
  }, []);

  const handleOpenInfoModal = useCallback(() => setOpenInfoModal(true), []);
  const handleCloseInfoModal = useCallback(() => setOpenInfoModal(false), []);

  const userTop8IsDirty = useMemo(
    () => user && !isEqual(selections, user.get("top_podcasts")),
    [selections, user]
  );

  const gaProps = useMemo(
    () => ({
      user_id: user ? user.get("id") : null,
      num_items: selections && selections.filter((i) => !!i).size,
    }),
    [selections, user]
  );

  const handleSetEditable = useCallback(() => setEditable(true), []);

  const handleLoginClick = useCallback(
    (e) => {
      e.stopPropagation();
      startAuthFlow(undefined, {
        analyticsVariables: {
          triggered_by: "Top8EditorLoginButton",
        },
      });
    },
    [startAuthFlow]
  );

  const onSave = useCallback(() => {
    if (!user) {
      startAuthFlow(authStates.LOGIN_FORM, {
        entityAction: "top8",
        entity_type: "top8",
        entity_type_name: "Top 8",
        top_podcasts: selections.toJS(),
        forward: "/users/me",
        analyticsVariables: {
          triggered_by: "Top8EditorSave",
        },
      });
    } else if (!userTop8IsDirty) {
      setEditable(false);
    } else {
      setSaving(true);
      setError(null);
      updateProfile({ top_podcasts: selections.toJS() })
        .then(() => {
          setSaving(false);
          setEditable(false);
        })
        .catch(() => {
          setError("Error Saving!");
          setSaving(false);
        });
    }
  }, [user, userTop8IsDirty, startAuthFlow, selections, updateProfile]);

  const handleDecideLaterClick = useCallback(() => {
    if (user) {
      history.push(getUserUrl(user));
    }

    startAuthFlow(true, {
      forward: "/users/me",
      analyticsVariables: {
        triggered_by: "Top8EditorDecideLater",
      },
    });
  }, [user, history, startAuthFlow]);

  const handleDiscardChanges = useCallback(() => {
    setSelections(user.get("top_podcasts"));
    setEditable(false);
  }, [user]);

  const { podcastSize, podcastsPerRow } = useMemo(() => {
    if (containerWidth > 0) {
      let perRow = 8;

      if (isTinyOrLess) {
        perRow = 2;
      } else if (isSmallOrLess) {
        perRow = 3;
      } else if (isMediumOrLess) {
        perRow = 4;
      } else if (isLargeOrLess) {
        perRow = 6;
      }

      return {
        podcastSize: containerWidth / perRow,
        podcastsPerRow: perRow + 1,
      };
    }

    return {};
  }, [
    containerWidth,
    isTinyOrLess,
    isSmallOrLess,
    isMediumOrLess,
    isLargeOrLess,
  ]);

  const podcastsToDisplay = useMemo(() => {
    if (
      containerWidth > 0 &&
      backgroundPodcasts &&
      backgroundPodcasts.size > 0
    ) {
      const rowsPerColumn = Math.ceil(containerHeight / podcastSize);
      const toDisplay = range(rowsPerColumn).reduce(
        (finalPodcasts, rowIndex) => {
          range(podcastsPerRow).forEach((columnIndex) => {
            const startingRowPodcastIndex = Math.ceil((rowIndex * 3) / 2);
            const itemToAddIndex = (startingRowPodcastIndex + columnIndex) % 8;

            finalPodcasts.push(backgroundPodcasts.get(itemToAddIndex));
          });

          return finalPodcasts;
        },
        []
      );

      return toDisplay;
    }

    return [];
  }, [
    containerWidth,
    backgroundPodcasts,
    containerHeight,
    podcastSize,
    podcastsPerRow,
  ]);

  const renderBackgroundImage = (podcast, index) => {
    if (podcast) {
      return (
        <img
          key={index}
          src={cachedImage(podcast.get("image_url"), podcastSize * 2)}
          alt={podcast.get("title")}
          title={podcast.get("title")}
          style={{
            width: podcastSize,
            height: podcastSize,
          }}
          width={podcastSize}
          height={podcastSize}
        />
      );
    }

    return null;
  };

  const renderBackground = () => {
    if (
      containerWidth > 0 &&
      backgroundPodcasts &&
      backgroundPodcasts.size > 0
    ) {
      return (
        <div
          className={css(styles.backgroundImages)}
          style={{
            maxWidth: containerWidth + podcastSize,
            width: containerWidth + podcastSize,
            left: -podcastSize / 2,
          }}
        >
          {podcastsToDisplay.map(renderBackgroundImage)}
        </div>
      );
    }

    return <div className={css(styles.defaultBackground)} />;
  };

  const renderFooterButtonsLoggedOut = () => (
    <div className={css(styles.footerButtons, styles.footerButtonsLoggedOut)}>
      <StandardButton
        label="Save Top 8"
        variation="pink"
        onClick={onSave}
        submitting={saving}
        disabled={!selections || selections.filter((i) => !!i).size === 0}
        gaAction="top8FinishClicked"
        gaProps={gaProps}
      />
      {error && <div className={css(styles.error)}>{error}</div>}
      <div
        className={css(styles.transparentLink)}
        onClick={handleDecideLaterClick}
      >
        Decide Later
      </div>
    </div>
  );

  const buttonProps = { fontSize: isSmallOrLess ? ".9rem" : ".75rem" };
  const renderFooterButtonsLoggedIn = () => (
    <div
      className={css(
        styles.footerButtons,
        editable && styles.footerButtonsEditing
      )}
    >
      <div
        className={css(
          styles.footerButtonsRow,
          editable && styles.footerButtonRowEditing
        )}
      >
        {!editable && (
          <Fragment>
            <div className={css(styles.footerButton)}>
              <StandardButton
                label="Edit your Top 8"
                variation="white"
                onClick={handleSetEditable}
                submitting={saving}
                {...buttonProps}
              />
            </div>
            <div className={css(styles.footerButton)}>
              <div className={css(styles.profileLinkContainer)}>
                <StandardButton
                  label="Continue To My Profile"
                  variation="pink"
                  to={getUserUrl(user)}
                  link
                  {...buttonProps}
                />
              </div>
            </div>
          </Fragment>
        )}
        {editable && (
          <Fragment>
            <StandardButton
              label="Save Changes"
              variation="pink"
              onClick={onSave}
              submitting={saving}
              gaAction="top8FinishClicked"
              gaProps={gaProps}
              {...buttonProps}
            />
            <div
              className={css(styles.transparentLink)}
              onClick={handleDiscardChanges}
            >
              {userTop8IsDirty ? "Discard Changes" : "Cancel"}
            </div>
          </Fragment>
        )}
      </div>
      {error && <div className={css(styles.error)}>{error}</div>}
    </div>
  );

  const renderShare = () => (
    <div className={css(styles.shareButtonContainer)}>
      <div className={css(styles.shareButton)}>
        <UserProfileSharingLinks
          user={user}
          label={
            <div className={css(styles.shareButton)}>
              <FontAwesomeIcon
                icon={faShareSquare}
                className={css(styles.shareButtonIcon)}
              />
              Share Top 8
            </div>
          }
          loading={saving}
          isTop8
          variation="none"
          customStyles={sharingLinkStyles}
          {...buttonProps}
        />
      </div>
    </div>
  );

  const renderInner = () => (
    <div className={css(styles.inner)}>
      <h1 className={css(styles.title, user && styles.titleWithUser)}>
        {user ? "My Top 8" : "Create your Top 8"}
      </h1>
      {!user && (
        <Fragment>
          <h2 className={css(styles.description)}>
            Select your favorite 8 podcasts to display on your personal
            Podchaser profile so you can show your friends and the world your
            podcast personality.
          </h2>
          <div className={css(styles.loginPrompt)}>
            If you already have a Top 8,
            <a onClick={handleLoginClick} className={css(styles.loginLink)}>
              log in to edit
            </a>
            .
          </div>
        </Fragment>
      )}
      <div className={css(styles.listContainer)}>
        <Top8Podcasts
          styles={top8Styles}
          squareStyles={top8SquareStyles}
          onChange={onChange}
          top_podcasts={selectedTopPodcastEntities}
          itemsPerRow={isSmallOrLess ? 2 : 4}
          itemPadding={12}
          outerPadding={0}
          fullWidth
          editable={editable}
        />
        {user && renderShare()}
      </div>
      {user ? renderFooterButtonsLoggedIn() : renderFooterButtonsLoggedOut()}
    </div>
  );

  const renderAdditionalInfoLinks = () => (
    <div>
      <Link to="/" className={css(styles.podchaserLink)}>
        <span className={css(styles.podchaserIcon)}>
          <PodchaserBrandLogo width={24} height={24} iconOnly />
        </span>
      </Link>
      <div className={css(styles.infoToggle)} onClick={handleOpenInfoModal}>
        <div className={css(styles.infoToggleText)}>
          <div>What is Podchaser?</div>
        </div>
        <FontAwesomeIcon icon={faInfoCircle} />
      </div>
    </div>
  );

  if (redirect) {
    return <Redirect to="/mytop8" />;
  }

  if (returnSaving) {
    return (
      <div className={css(styles.savingContainer)}>
        <div className={css(styles.savingOverlay)}>
          <LoadingOverlay
            styles={loadingStyles}
            noPadding
            noOverlay
            key="top8EditorLoadingOverlay"
          />
          <div className={css(styles.savingOverlayTitle)}>
            Just saving your list <FontAwesomeIcon icon={faSmile} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={css(
        styles.container,
        transparentPadding && styles.containerTransparentPadding
      )}
    >
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={throttle(handleResize, 100)}
      />
      <Top8EditorStructuredData />
      <div className={css(styles.backgroundContainer)}>
        {renderBackground()}
      </div>
      <div className={css(styles.backgroundOverlay)} />
      {renderInner()}
      {isFullscreen && renderAdditionalInfoLinks()}
      {openInfoModal && <Top8EditorInfoModal onClose={handleCloseInfoModal} />}
    </div>
  );
}

Top8Editor.propTypes = { transparentPadding: PropTypes.bool };

Top8Editor.defaultProps = { transparentPadding: false };

export default memo(Top8Editor);
