import Loadable from "@loadable/component";

const UserProfileSharingLinks = Loadable(() =>
  import("./UserProfileSharingLinks")
);

const UserProfileSharingLinksContainer = (props) => {
  return <UserProfileSharingLinks {...props} />;
};

export default UserProfileSharingLinksContainer;
