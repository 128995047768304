import { memo, useMemo } from "react";
import Helmet from "react-helmet-async";

import { getBaseUrl } from "constants/base";
import { generateMetaArray } from "utils/meta";

function Top8EditorStructuredData() {
  const canonicalUrl = `${getBaseUrl()}/top8`;

  const title = "Create Your Top 8";
  const metaArray = useMemo(
    () =>
      generateMetaArray({
        title,
        url: canonicalUrl,
        image: {
          url: "http://images.podchaser.com/marketing/Marketing+-+Top+8+-+Facebook+-+Create+Top+8.png",
        },
        description:
          "Select your favorite 8 podcasts to display on your personal Podchaser " +
          "profile so you can show your friends and the world your podcast personality.",
      }),
    [canonicalUrl]
  );
  const links = useMemo(
    () => [{ rel: "canonical", href: canonicalUrl }],
    [canonicalUrl]
  );

  return <Helmet title={title} meta={metaArray} link={links} />;
}

Top8EditorStructuredData.propTypes = {};

Top8EditorStructuredData.defaultProps = {};

export default memo(Top8EditorStructuredData);
