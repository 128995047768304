import { fromJS, List, Map } from "immutable";
import { useEffect, useMemo, useRef } from "react";

import { loadSpecificPodcast as loadSpecificPodcastAction } from "actions/podcast";
import { selectSpecificPodcast, selectPodcastLoading } from "selectors/podcast";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";

const useTop8PodcastsForUser = (user) => {
  const localEntityLoadingRef = useRef([]);

  const { loadSpecificPodcast } = useActionCreators({
    loadSpecificPodcast: loadSpecificPodcastAction,
  });

  const topPodcastIds = useMemo(
    () => (user ? user.get("top_podcasts") : null),
    [user]
  );

  const podcasts = useReduxState(
    (state) => {
      if (topPodcastIds) {
        return topPodcastIds
          .map((podcastId) => {
            if (podcastId) {
              const entity = podcastId
                ? selectSpecificPodcast(state, podcastId)
                : null;
              const loading = podcastId
                ? selectPodcastLoading(state, podcastId)
                : false;

              return Map({ id: podcastId, entity, loading });
            }

            return null;
          })
          .filter((pod) => !!pod);
      }

      return List([]);
    },
    [topPodcastIds]
  );

  useEffect(() => {
    // load any podasts that aren't loaded
    for (let i = 0; i < 8; i++) {
      const podcastState = podcasts.get(i);

      if (
        podcastState &&
        !podcastState.get("entity") &&
        !podcastState.get("loading") &&
        !localEntityLoadingRef.current.includes(podcastState.get("id"))
      ) {
        loadSpecificPodcast(podcastState.get("id"));
        localEntityLoadingRef.current.push(podcastState.get("id"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcasts]);

  return podcasts;
};

export const getTop8Podcasts = (state, podcastIds) =>
  podcastIds
    .map((id) => {
      if (!id) {
        return null;
      }

      return fromJS({
        id,
        entity: selectSpecificPodcast(state, id),
      });
    })
    .filter((pod) => !!pod);

export function useTop8Podcasts(podcastIds) {
  return useReduxState(
    (state) => getTop8Podcasts(state, podcastIds),
    [podcastIds]
  );
}

export default useTop8PodcastsForUser;
